<template>
  <v-card>
    <v-btn
      @click="$emit('input', false)"
      class="close-button"
      icon
      color="black"
    >
      <v-icon>clear</v-icon>
    </v-btn>
    <v-card-title class="justify-center text-h5 font-weight-bold">
      {{ $t("dashboardHistoryComponents.manageTagsDialog.title") }}
    </v-card-title>
    <v-row justify="center" no-gutters>
      <v-col cols="11">
        <v-data-table
          :headers="headers"
          :items="tagsToDisplay"
          :items-per-page="5"
          item-key="tableId"
          height="345px"
          class="mx-5"
          :page="page"
          @page-count="pageCount = $event"
        >
          <template slot="no-data">
            <v-row justify="center" :value="true">
              <v-col cols="3">
                <v-row justify="center">
                  <v-img
                    contain
                    max-width="100px"
                    src="@/assets/images/undraw_no_data.svg"
                  ></v-img>
                </v-row>
              </v-col>
              <v-col cols="12">
                {{ $t("dashboardHistoryComponents.manageTagsDialog.noTags") }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.name="{ item }">
            <v-row align="center" justify="space-between" no-gutters>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-if="item.edit"
                  v-model="editedTag"
                  hide-details
                  append-icon="done"
                  @click:append="editTagInTable(item)"
                  @keydown.enter="editTagInTable(item)"
                  @input="showErrorMessage = false"
                >
                </v-text-field>
              </v-col>
              <v-col v-show="!item.edit" cols="10" class="pa-0">
                <v-text-field
                  :value="item.name"
                  background-color="transparent"
                  readonly
                  flat
                  solo
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" v-show="!item.edit" class="pa-0">
                <v-row justify="space-around">
                  <v-icon @click="toggleTagEdit(item)">edit</v-icon>
                  <v-icon @click="removeTagFromTable(item)">close</v-icon>
                </v-row>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.endUserCount="{ item }">
            <div>{{ item.endUserCount }}</div>
          </template>

          <template v-slot:body.append>
            <tr>
              <td v-if="!$vuetify.breakpoint.xs"></td>
              <td>
                <v-row align="center" no-gutters>
                  <v-col cols="9" class="mr-2">
                    <v-text-field
                      v-model="tagToAdd"
                      outlined
                      dense
                      solo
                      flat
                      hide-details
                      full-width
                      :label="
                        $t(
                          'dashboardHistoryComponents.manageTagsDialog.addNewLabel'
                        )
                      "
                      @keydown.enter="addTagToTable()"
                      @input="showErrorMessage = false"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-icon color="primary" @click="addTagToTable()"
                      >mdi-plus</v-icon
                    >
                  </v-col>
                </v-row>
              </td>
              <td v-if="!$vuetify.breakpoint.xs"></td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="11">
        <p
          class="red--text text-center mb-0"
          v-show="
            showWarningMessage && !showErrorMessage && endUsersToDeleteCount > 0
          "
        >
          {{
            $tc(
              "dashboardHistoryComponents.manageTagsDialog.saveTextTagDeletionEndUsers",
              endUsersToDeleteCount
            )
          }}
        </p>
        <p
          class="red--text text-center mb-0"
          v-show="
            showWarningMessage &&
              !showErrorMessage &&
              teamMembersToDeleteCount > 0
          "
        >
          {{
            $tc(
              "dashboardHistoryComponents.manageTagsDialog.saveTextTagDeletionTeamMembers",
              teamMembersToDeleteCount
            )
          }}
        </p>
        <v-card
          color="error"
          class="white--text py-2"
          v-show="showErrorMessage"
        >
          <p class="text-center mb-0">
            {{ errorMessage }}
          </p>
        </v-card>
      </v-col>
      <v-col cols="4" class="mr-5" v-show="changesMade">
        <v-btn
          class="my-4"
          color="primary"
          outlined
          rounded
          @click="$emit('input', false)"
          width="100%"
          >{{ $t("buttons.cancel") }}</v-btn
        >
      </v-col>
      <v-col cols="4">
        <v-btn
          class="my-4"
          color="primary"
          rounded
          @click="saveChanges"
          :loading="updatingTags"
          :disabled="!changesMade || !!tagBeingEdited"
          width="100%"
          >{{ $t("buttons.save") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { expireTags, addTags, editTags } from "@/customApi";
import Vue from "vue";

export default {
  name: "ManageTagsDialog",
  props: {
    endUsers: Array,
    teamMembers: Array,
    teamTags: Array
  },
  watch: {
    endUsers() {
      // Reset current tags to match updated endUsers
      this.currentTags = this.getTagsWithCounts();
      this.changesMade = false;
    }
  },
  data() {
    return {
      page: 1,
      pageCount: 1,
      currentTags: this.getTagsWithCounts(),
      headers: [
        {
          text: "",
          align: "left",
          width: "1%",
          sortable: false,
          value: "icon"
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.manageTagsDialog.headers.name"
          ),
          align: "left",
          value: "name"
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.manageTagsDialog.headers.count"
          ),
          align: "left",
          value: "endUserCount"
        }
      ],
      updatingTags: false,
      showWarningMessage: false,
      showErrorMessage: false,
      errorMessage: "",
      changesMade: false,
      endUsersToDeleteCount: 0,
      teamMembersToDeleteCount: 0,
      tagToAdd: "",
      editedTag: ""
    };
  },
  methods: {
    removeTagFromTable(tag) {
      this.showErrorMessage = false;
      this.endUsersToDeleteCount += tag.endUserCount;
      this.teamMembersToDeleteCount += tag.teamMemberCount;
      let index = this.currentTags.indexOf(tag);
      this.currentTags.splice(index, 1);
      this.showWarningMessage = true;
      this.changesMade = true;
    },
    addTagToTable() {
      this.showErrorMessage = false;
      let tagExists = !!this.currentTags.find(
        x => x.name.toLowerCase() == this.tagToAdd.toLowerCase()
      );
      if (tagExists) {
        this.displayError(
          this.$t(
            "dashboardHistoryComponents.manageTagsDialog.errors.tagExists"
          )
        );
        return;
      }
      if (this.tagToAdd) {
        let index = this.currentTags.length + 1;
        this.currentTags.push({
          id: "",
          name: this.tagToAdd,
          endUserCount: 0,
          teamMemberCount: 0,
          tableId: index
        });
        this.tagToAdd = "";
        this.changesMade = true;
        Vue.nextTick(() => {
          this.page = this.pageCount;
        });
      }
    },
    toggleTagEdit(tag) {
      if (this.tagBeingEdited && this.tagBeingEdited !== tag) {
        delete this.tagBeingEdited.edit;
      }
      this.editedTag = tag.name;
      tag.edit = true;
      this.currentTags = [...this.currentTags];
    },
    editTagInTable(tag) {
      let sameTagName = this.editedTag == tag.name;
      let tagExists = !!this.currentTags.find(x => x.name == this.editedTag);
      if (!sameTagName && tagExists) {
        this.displayError(
          this.$t(
            "dashboardHistoryComponents.manageTagsDialog.errors.tagExists"
          )
        );
        return;
      }
      this.showErrorMessage = false;
      this.changeTagName(tag);
      this.changesMade = !sameTagName && !!this.editedTag;
      this.editedTag = "";
    },
    changeTagName(tag) {
      if (this.editedTag) {
        tag.name = this.editedTag;
      }
      delete tag.edit;
      this.currentTags = [...this.currentTags];
    },
    displayError(message) {
      this.showErrorMessage = true;
      this.errorMessage = message;
    },
    async saveChanges() {
      let originalTags = this.getTagsWithCounts();
      this.showWarningMessage = false;
      this.showErrorMessage = false;
      this.updatingTags = true;
      try {
        await this.expireTags(originalTags);
        await this.addTags();
        await this.editTags(originalTags);
        this.$emit("input", false);
      } catch (error) {
        this.$logger.captureException(error);
        this.showErrorMessage = true;
        this.errorMessage = this.$t(
          "dashboardHistoryComponents.manageTagsDialog.errors.updating"
        );
      } finally {
        // After making updates to tags, update table so changes reflected in table.
        // If there is an error making updates, this ensures table only shows changes succesfully made.
        this.$emit("update");
        this.updatingTags = false;
        this.endUsersToDeleteCount = 0;
        this.teamMembersToDeleteCount = 0;
      }
    },
    async expireTags(originalTags) {
      let tagsToDelete = [];
      for (let i = 0; i < originalTags.length; i++) {
        let tag = originalTags[i];
        if (!this.currentTags.find(x => x.id == tag.id)) {
          tagsToDelete.push({ id: tag.id });
        }
      }
      if (tagsToDelete.length > 0) {
        await expireTags(tagsToDelete);
      }
    },
    async addTags() {
      let tagsToAdd = this.currentTags
        .filter(tag => !tag.id)
        .map(x => ({ name: x.name }));
      if (tagsToAdd.length > 0) {
        await addTags(tagsToAdd);
      }
    },
    async editTags(originalTags) {
      let tagsToEdit = [];
      for (let i = 0; i < originalTags.length; i++) {
        let originalTag = originalTags[i];
        let currentTag = this.currentTags.find(x => x.id == originalTag.id);
        if (currentTag && currentTag.name !== originalTag.name) {
          tagsToEdit.push(currentTag);
        }
      }
      if (tagsToEdit.length > 0) {
        await editTags(tagsToEdit);
      }
    },
    getTagsWithCounts() {
      // Get unique tags from end users with tags
      let endUserTags = this.endUsers
        .flatMap(x => x.tags)
        .map(t => ({
          id: t.id,
          name: t.name
        }));
      let teamMemberTags = this.teamMembers
        .flatMap(x => x.tags)
        .map(t => ({ id: t.id, name: t.name }));

      let combinedTags = endUserTags.concat(teamMemberTags);
      let uniqueTags = combinedTags.filter(
        (item, index, arr) => arr.findIndex(t => t.name === item.name) === index
      );

      let mappedTags = uniqueTags.map(x => {
        let endUserCount = endUserTags.filter(a => a.name == x.name).length;
        let teamMemberCount = teamMemberTags.filter(a => a.name == x.name)
          .length;
        return {
          id: x.id,
          name: x.name,
          endUserCount: endUserCount,
          teamMemberCount: teamMemberCount
        };
      });

      // Add any tags not used in end users but still associated to team
      let notUsedTags = this.teamTags
        .filter(x => x.expiredAt === null)
        .filter(x => uniqueTags.findIndex(t => t.id == x.id) === -1)
        .map(x => ({
          id: x.id,
          name: x.name,
          endUserCount: 0,
          teamMemberCount: 0
        }));
      let allTags = mappedTags.concat(notUsedTags);

      // Add tableId to give unique id for data table and return sorted alphabetically
      let allTagsWithTableIds = allTags.map((x, i) => ({ ...x, tableId: i }));
      this.sortByTagName(allTagsWithTableIds);
      return allTagsWithTableIds;
    },
    sortByTagName(tags) {
      tags.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
  },
  computed: {
    tagBeingEdited() {
      return this.currentTags.find(x => !!x.edit);
    },
    tagsToDisplay() {
      return this.currentTags.filter(t => t.name != "Everything");
    }
  }
};
</script>
