var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-btn",
        {
          staticClass: "close-button",
          attrs: { icon: "", color: "black" },
          on: {
            click: function($event) {
              return _vm.$emit("input", false)
            }
          }
        },
        [_c("v-icon", [_vm._v("clear")])],
        1
      ),
      _c(
        "v-card-title",
        { staticClass: "justify-center text-h5 font-weight-bold" },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("dashboardHistoryComponents.manageTagsDialog.title")
              ) +
              " "
          )
        ]
      ),
      _c(
        "v-row",
        { attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "11" } },
            [
              _c(
                "v-data-table",
                {
                  staticClass: "mx-5",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.tagsToDisplay,
                    "items-per-page": 5,
                    "item-key": "tableId",
                    height: "345px",
                    page: _vm.page
                  },
                  on: {
                    "page-count": function($event) {
                      _vm.pageCount = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                align: "center",
                                justify: "space-between",
                                "no-gutters": ""
                              }
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  item.edit
                                    ? _c("v-text-field", {
                                        attrs: {
                                          "hide-details": "",
                                          "append-icon": "done"
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editTagInTable(item)
                                          },
                                          keydown: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.editTagInTable(item)
                                          },
                                          input: function($event) {
                                            _vm.showErrorMessage = false
                                          }
                                        },
                                        model: {
                                          value: _vm.editedTag,
                                          callback: function($$v) {
                                            _vm.editedTag = $$v
                                          },
                                          expression: "editedTag"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !item.edit,
                                      expression: "!item.edit"
                                    }
                                  ],
                                  staticClass: "pa-0",
                                  attrs: { cols: "10" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: item.name,
                                      "background-color": "transparent",
                                      readonly: "",
                                      flat: "",
                                      solo: "",
                                      "hide-details": ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !item.edit,
                                      expression: "!item.edit"
                                    }
                                  ],
                                  staticClass: "pa-0",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { justify: "space-around" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleTagEdit(item)
                                            }
                                          }
                                        },
                                        [_vm._v("edit")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.removeTagFromTable(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("close")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.endUserCount",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("div", [_vm._v(_vm._s(item.endUserCount))])]
                      }
                    },
                    {
                      key: "body.append",
                      fn: function() {
                        return [
                          _c("tr", [
                            !_vm.$vuetify.breakpoint.xs ? _c("td") : _vm._e(),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-row",
                                  {
                                    attrs: { align: "center", "no-gutters": "" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { cols: "9" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            outlined: "",
                                            dense: "",
                                            solo: "",
                                            flat: "",
                                            "hide-details": "",
                                            "full-width": "",
                                            label: _vm.$t(
                                              "dashboardHistoryComponents.manageTagsDialog.addNewLabel"
                                            )
                                          },
                                          on: {
                                            keydown: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.addTagToTable()
                                            },
                                            input: function($event) {
                                              _vm.showErrorMessage = false
                                            }
                                          },
                                          model: {
                                            value: _vm.tagToAdd,
                                            callback: function($$v) {
                                              _vm.tagToAdd = $$v
                                            },
                                            expression: "tagToAdd"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "2" } },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.addTagToTable()
                                              }
                                            }
                                          },
                                          [_vm._v("mdi-plus")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            !_vm.$vuetify.breakpoint.xs ? _c("td") : _vm._e()
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "no-data" },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", value: true } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      contain: "",
                                      "max-width": "100px",
                                      src: require("@/assets/images/undraw_no_data.svg")
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "dashboardHistoryComponents.manageTagsDialog.noTags"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "11" } },
            [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.showWarningMessage &&
                        !_vm.showErrorMessage &&
                        _vm.endUsersToDeleteCount > 0,
                      expression:
                        "\n          showWarningMessage && !showErrorMessage && endUsersToDeleteCount > 0\n        "
                    }
                  ],
                  staticClass: "red--text text-center mb-0"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "dashboardHistoryComponents.manageTagsDialog.saveTextTagDeletionEndUsers",
                          _vm.endUsersToDeleteCount
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.showWarningMessage &&
                        !_vm.showErrorMessage &&
                        _vm.teamMembersToDeleteCount > 0,
                      expression:
                        "\n          showWarningMessage &&\n            !showErrorMessage &&\n            teamMembersToDeleteCount > 0\n        "
                    }
                  ],
                  staticClass: "red--text text-center mb-0"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "dashboardHistoryComponents.manageTagsDialog.saveTextTagDeletionTeamMembers",
                          _vm.teamMembersToDeleteCount
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showErrorMessage,
                      expression: "showErrorMessage"
                    }
                  ],
                  staticClass: "white--text py-2",
                  attrs: { color: "error" }
                },
                [
                  _c("p", { staticClass: "text-center mb-0" }, [
                    _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.changesMade,
                  expression: "changesMade"
                }
              ],
              staticClass: "mr-5",
              attrs: { cols: "4" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "my-4",
                  attrs: {
                    color: "primary",
                    outlined: "",
                    rounded: "",
                    width: "100%"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("input", false)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "my-4",
                  attrs: {
                    color: "primary",
                    rounded: "",
                    loading: _vm.updatingTags,
                    disabled: !_vm.changesMade || !!_vm.tagBeingEdited,
                    width: "100%"
                  },
                  on: { click: _vm.saveChanges }
                },
                [_vm._v(_vm._s(_vm.$t("buttons.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }